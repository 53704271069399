@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,700&display=swap');


*,
*::before,
*::after {
  box-sizing: border-box;
  scroll-behavior: smooth;
  transition: 0.5s ease-in;
}


h1, h2, h3,h4, h5, h6 {
  font-family: 'Ubuntu', sans-serif !important;

}


body {
  font-family: 'Ubuntu', sans-serif;
  background-color:#ffffff !important;
}


nav {
  background-color: #fdfeff;
  color: #000;
  height: 12vh !important;
  
}


.navbar-brand img{
  width: 10rem !important;
  margin-left: 4rem !important;
}


.offcanvas {
  background-color: #ffffff;
}


.dropdown-menu {
  background-color: #f5f5f5 !important;
  border: none !important;
}


span .navbar-toggler-icon {
  background-color: #000000 !important;
}


.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;
}


.toggler-icon {
  width: 100%;
  height: 2px;
  background-color: #000000;
  display: block;
  position: absolute;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  border-radius: 10px;
  margin-right: 100px !important;
}


/*when navigation is clicked*/
.navbar-toggler .top-bar {
  margin-top: 7px;
  transform: rotate(0deg);
}


.navbar-toggler .middle-bar {
  margin-top: 13.5px;
  transform: rotate(0deg);
}


.navbar-toggler .bottom-bar {
  margin-top: 0px;
  transform: rotate(0deg);
}


/*state when navbar is collapsed*/
.offcanvas-header .btn-close {
  color: #009F0F !important;
}


.nav-item {
  margin-left: 20px !important;
}


.nav-link {
  font-weight: 800;
  font-size: 1.28rem !important;
  color: #000000 !important;
}


.nav-link:hover {
  color: #009F0F !important ;
}


.animate-img {
  position: absolute;
  top: 0;
  left: 50% !important;
  width: 40%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}


.active {
  opacity: 1;
}


.hero-section{
  background-color:#009F0F;
  background-image: url('../img/bg2.png');
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  height: 100vh;
}


.hero-section span {

  color: #FFE500;
}


.hero-section p {

  font-size: 1.2rem;
}


.bi-star-fill{
margin-left: 10px;
font-size: 20px;
color: #FFE500;
}

.enroll-button{
  background-color: #009F0F;
  width: 30% !important;
  padding: 10px;
  border: 1px solid #009F0F !important;
  border-radius: 10px;

}


.enroll-button a{
  color: #ffffff !important;
  text-decoration: none !important;
  font-size: 18px;
  font-weight: 600 ;

}


.enroll-button:hover{

  background-color: white !important;
  border: 1px solid #009F0F !important;
  color: #009F0F !important;

}

.enroll-button:hover a {

  color: #009F0F !important;
}


.about span{
  color: #009F0F;
}


.about p{
  line-height: 35px;
}


.about i.abt-icon{
  color: #009F0F !important;
}


.about img{
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 90% !important;
}


.benefit .card {

  border: none;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-color:#009F0F;
  background-image: url('../img/bg3.png');
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: scale-down;
  border-radius: 20px !important;
  height: 90% !important;
  color: white
}


.benefit .card-title {

  font-size: 1.5rem !important;
}



.benefit i {

  font-size: 4rem !important;
  color: #FFE500;
}



.courses span {
  color:#009F0F;

}




.courses .card {

  border: none;
}


.card-footer {
  
  border: none !important;
  background-color: transparent !important;
}


.card{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-top: 30px !important;
  margin-bottom: 30px !important;

}


.card-img {

  width: 100% !important;
}


.testimonials {
  padding: 50px 0;
}


.testimonials h2 {
  text-align: center;
  margin-bottom: 30px;
}


.carousel-indicators [data-bs-target]{
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px !important;
  height: 10px !important;
  border-radius: 100% !important;
  border: 5px solid #009F0F !important;
  margin-right: 5px !important;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFE500 !important ;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 1;
  transition:  0.6s ease;
}


.testimonials span{
  color: #009F0F !important;
}

.accordion-button:not(.collapsed) {
  background-color: #009F0F !important;
  color: #fff !important;
}

:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}


.accordion-button:not(.collapsed) {

  color: #fff !important;
  background-color: #009F0F !important;
}
 


.accordion .accordion-item{

  background-color: white !important;
  border: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


.accordion button{
  border: none !important ;
}


button.accordion-button{
  color: #000 !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  background-color: #ffffff !important;
}


.accordion-item{
  margin-top: 10px !important;
}

.faq span{
  color: #009F0F !important;
}


.faq img {
  border-radius: 1.3rem !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.testi span {

  color: #009F0F !important;
}


.testi .card {

  background-image: url('../img/blockquote.png');
  background-size: contain;
  background-size: 50px; /* adjust size as needed */
  background-repeat: no-repeat;
  background-position: calc(25% - 50px) 24px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: none !important;
}


.testi .card img {

  width: 2.5rem !important;
  border-radius: 50% !important;
}


.testi .card small {

  font-size: 1rem;
}

.started .card {

  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 2rem !important;
  background-image: url('../img/bg2.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff !important;
}

.started button {

  width: 50% !important;
}

.footer {
  background-color:#000000!important;
  padding: 40px 0;
  color: #ffffff;
}


.footer button.social-links{
  border-radius: 5px !important;
  background-color: #009F0F !important;
  color: #ffffff !important;
  border: 0px !important;
  font-weight: 700 !important;
}


.footer .footer-link{
  color: #ffffff;
  text-decoration: none;
  font-size: 1.1rem !important;
}


.footer p {
  margin-bottom: 20px;
  line-height: 1.8rem !important;
  font-size: 1rem !important;
}

.footer h1 {

  color: #009F0F !important;
}



.footer form {
  margin-bottom: 20px;
  border: 1px solid #000  !important;
}


.form {
  display: flex;
  align-items: center;
}


.form input {
  height: 48px ;
  flex: 1;
  width: 100%;
  border-radius: 10px !important;
  padding: 10px !important;
}



.form button {
  flex-shrink: 0;
  background-color: #009F0F !important;
  margin-left: 10px;
  height: 45px ;
  border: none !important;
}



.footer img {
  margin-top: 20px;
}




.footer p {
  font-size: 13px;
  
}


.footer ul li {
  margin-bottom: 10px;
}



.footer i {
  margin-right: 10px;
}



/********** RESPONSIVENESS **********/

/* For 480 Resolution - Laptop*/
@media(min-width: 320px) and (max-width: 480px) {
  /* STYLES GO HERE */


}


/* For 640 Resolution - mobile */
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {

  

  .nav {
    height: 9vh !important;
    
  }
  .navbar-brand img{
    width: 7rem !important;
    margin-left: 0rem !important;
  }


  .fixed-top {

    height: 5rem !important;
  }


  .hero-section p {

    font-size: 1.0rem;
    margin-bottom: 2rem !important;

  }

  .buttons {

      text-align: center !important;
      justify-content: center !important;

  }
  

  .benefit .card {

    border: none;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-color:#009F0F;
    background-image: url('../img/bg1.png');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px !important;
    height: 90% !important;
    color: white
  }
  
  .benefit .card-title {
  
    font-size: 1rem !important;
  }
  
  
  
  .benefit i {
  
    font-size: 2.5rem !important;
    color: #FFE500;
  }


  .started button {

    width: 100% !important;
  }

  .testi h1{
    font-size: calc(1.575rem + 1.0vw) !important;
  }


  .navbar-toggler {

    margin-bottom: 1rem !important;
  }

  .offcanvas {

    border-radius: 5rem 0rem 0rem 0rem;
  }

  .offcanvas-header {
    margin: 10% !important;
  }

  .offcanvas-body {
    padding: 2rem !important;
  }
}


/* For 1024 Resolution - laptop 1 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  
}

/* For 1366 Resolution  -- laptop 2*/
@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  /* STYLES GO HERE */
}

/** desktop screen */
@media only screen and (min-width: 1370px) and (max-width: 1605px) {
  /* STYLES GO HERE */
}

/*Ipad Orientation : Landscape */
@media only screen and (orientation: landscape) and (min-device-pixel-ratio: 1) and (min-device-width: 768px) and (max-device-width: 1007px) {
  /* STYLES GO HERE */
}

/*ipad Orientation : Portrait */
@media only screen and (orientation: portrait) and (min-device-pixel-ratio: 1) and (min-device-width: 768px) and (max-device-width: 1007px) {
  /* STYLES GO HERE */
}